import * as React from "react";
import Head from "next/head";
import { AppCacheProvider } from "@mui/material-nextjs/v14-pagesRouter";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AnalyticsProvider } from "use-analytics";
import { analytics } from "@/lib/AtlasAnalytics";
import { AdsProvider } from "@/lib/ads";
import theme from "@/lib/theme";
import { ApolloProvider } from "@apollo/client";
import { UserProvider } from "@auth0/nextjs-auth0";
import { useApollo } from "@/lib/apolloClient";
import { Router, useRouter } from "next/router";
import { getCanonicalUrl, needsPageLoader, staticUrl } from "@/lib/utils";
import NProgress from "nprogress";
import { DEFAULT_COVER_PHOTO } from "@/lib/constants";

const useRouteProgress = () => {
  React.useEffect(() => {
    const handleStart = (url) => needsPageLoader(url) && NProgress.start();
    const handleComplete = (url) => needsPageLoader(url) && NProgress.done();
    const handleError = (url) => needsPageLoader(url) && NProgress.done();

    Router.events.on("routeChangeStart", handleStart);
    Router.events.on("routeChangeComplete", handleComplete);
    Router.events.on("routeChangeError", handleError);

    return () => {
      Router.events.off("routeChangeStart", handleStart);
      Router.events.off("routeChangeComplete", handleComplete);
      Router.events.off("routeChangeError", handleError);
    };
  }, []);
};

export default function MyApp(props) {
  const { Component, pageProps } = props;
  const router = useRouter();
  const canonicalUrl = getCanonicalUrl(router.asPath);
  const apolloClient = useApollo(pageProps.initialApolloState);

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  useRouteProgress();

  return (
    <AppCacheProvider {...props}>
      <Head>
        <title key="title">AtlasGuru</title>
        <meta
          name="description"
          content="AtlasGuru is a fresh new travel content platform where real people share their travel adventures, stories and itineraries to help others plan their dream trip."
          key="description"
        />
        <meta name="image" content={DEFAULT_COVER_PHOTO} key="image" />
        <meta
          name="robots"
          key="robots:maxImagePreview"
          content="max-image-preview:large"
        />
        {/* https://developer.mozilla.org/en-US/docs/Web/HTTP/Client_hints */}
        <meta httpEquiv="Accept-CH" content="DPR" />
        {/* Use minimum-scale=1 to enable GPU rasterization */}
        <meta
          name="viewport"
          key="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, maximum-scale=5"
        />
        <meta property="fb:admins" content="2012086725486339" key="fb:admins" />
        <meta
          name="p:domain_verify"
          content="41a5520c11c047968d8fe7e4762dd1d0"
          key="p:domain_verify"
        />

        {/* Schema.org for Google */}
        <meta itemProp="name" content="AtlasGuru" key="itemProp:name" />
        <meta
          itemProp="description"
          key="itemProp:description"
          content="Itineraries by travelers for  travelers"
        />
        <meta
          itemProp="image"
          key="itemProp:image"
          content={DEFAULT_COVER_PHOTO}
        />

        {/* Open Graph general (Facebook, Pinterest & Google+) */}
        <meta property="og:title" key="og:title" content="AtlasGuru" />
        <meta
          property="og:description"
          key="og:description"
          content="Itineraries by travelers for  travelers"
        />
        <meta
          property="og:url"
          key="og:url"
          content="https://www.atlasguru.com"
        />
        <meta
          property="og:site_name"
          key="og:site_name"
          content="AtlasGuru - Itineraries by travelers for travelers"
        />
        <meta
          property="og:image"
          key="og:image"
          content={DEFAULT_COVER_PHOTO}
        />
        <meta property="og:image:width" content="700" key="og:image:width" />
        <meta property="og:image:height" content="525" key="og:image:height" />
        <meta property="og:type" content="website" key="og:type" />

        <link rel="canonical" key="canonical" href={canonicalUrl} />
        <link
          rel="icon"
          type="image/x-icon"
          href={staticUrl(require("../static/favicon.ico"))}
        />
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ApolloProvider client={apolloClient}>
          <AnalyticsProvider instance={analytics}>
            <UserProvider>
              <AdsProvider>
                <Component {...pageProps} />
              </AdsProvider>
            </UserProvider>
          </AnalyticsProvider>
        </ApolloProvider>
      </ThemeProvider>
    </AppCacheProvider>
  );
}
